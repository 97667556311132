@import url("https://fonts.googleapis.com/css2?family=Open+Sans&family=Ubuntu&display=swap");

html,
body {
  font-family: "Ubuntu", sans-serif !important;
}

.makeStyles-toolbar-10 {
  display: none;
}

.MuiIconButton-root:hover {
  background-color: unset !important;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  margin: 32px;
  position: relative;
  overflow-y: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  max-height: calc(100% - 64px);
  max-width: 600px;
  width: 600px;
  min-height: auto;
}

input::placeholder {
  font-size: 12px;
}
.clickable-image {
  cursor: pointer;
}

/* TABLE */

thead {
  background-color: #deecef;
  padding: 16px, 243px, 16px, 16px;
}

tbody > tr:nth-child(even) {
  background-color: #f4f4f4;
}

/* FILE UPLOADER */

.fileUploader > label {
  background: #f3f6f8;
  border: 1px dashed #c1c1c1;
  border-radius: 3px;
  height: 160px;
}

.cancel_button {
  background: #ffffff;
  border: 1px solid #023e8a;
  border-radius: 3px;
  padding: 8px 24px;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  cursor: pointer;

  color: #023e8a;
  margin: 0 24px;
}

.workCategory-select {
  width: 100%;
}

.upload_button {
  background: #023e8a;
  border-radius: 3px;
  padding: 8px 24px;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  cursor: pointer;

  color: #ffffff;
}

/* React Select */

.react_select > div > div:nth-child(2) {
  background-color: #00b4d8;
}

.css-1s2u09g-control,
.css-1insrsq-control {
  min-height: 32px !important;
  background-color: #f3f6f8 !important;
}

.react_breakdown_select {
  width: 50%;
}

::before,
::after {
  border-bottom: unset !important;
}
.react_select > div {
  background: #F3F6F8;;
  min-height: 30px;
}

.disabled_select > div {
  background: white;
  min-height: 30px;
}

.select_disabled_init  > div{
  background: white;

}



.react_select > div:nth-child(1) {
  display: none !important;
}

::before,
::after {
  border-bottom: unset !important;
}

.Mui-disabled {
  background: white !important;
  padding: 0px !important;
}

.css-1insrsq-control {
  background: white !important;
}

/* Custom Tabs */

.MainTab {
  padding: 12px 24px !important;
}

.cursor-pointer {
  cursor: pointer;
  color: blue;
}

.search_drop {
  border: 1px solid #c5c5c5;
  border-top: none;
  background: #fff;
  padding: 5px;
  position: absolute;
  width: 97%;
  z-index: 200;
  max-height: 200px;
  overflow-y: scroll;
}

.cus_search_drop { 
  border: 1px solid #c5c5c5;
  border-top: none;
  background: #fff;
  padding: 5px;
  position: absolute;
  width: 700px !important;
  z-index: 200;
  
  max-height: 85px;
  overflow-y: scroll;
  margin-top: 130px;
}

.search_items {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  cursor: pointer;
}

.search_dropdown {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  width: 95%;
  height: 31px;
  border: 1px solid #dedede;
  padding: 0px 10px;
  background: #f3f6f8;
}

.description_textArea {
  width: 100%;
}

#search:disabled {
  background-color: white;
  opacity: 0.7;
}

textarea:disabled {
  background-color: white !important;
  opacity: 0.7;
}

textarea:disabled {
  background: #f3f6f8;
}

/* td {
  padding: 10px !important;
} */

/* input:disabled {
  border-spacing: 2px;
} */

/* .css-1insrsq-control {
  background-color: #f3f6f8 !important;
} */

/* .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.css-3bmhjh-MuiPaper-root-MuiPopover-paper {
  top: 150px !important;
  left: 950px !important;
} */

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.css-3bmhjh-MuiPaper-root-MuiPopover-paper:has(
    .datePicker
  ) {
  top: 150px !important;
  left: 950px !important;
}

.search_dropdown,
.search_items {
  color: black !important;
}

.cursor_pointer {
  cursor: pointer;
}

.MuiPopover-paper {
  top: 144px;
  left: 824px;
}

.agreement_desc {
  padding: 10px;
  border: 1px solid gray;
  margin: 10px 0;
  max-height: 500px;
  overflow-y: scroll;
}

.active {
  font-weight: 800;
}

.MuiListItem-gutters {
  padding-left: 5px !important;
  padding-right: unset !important;
}

.searchbar_select {
  border: unset;
}

.dispute_dropdown > div:nth-child(3) > div:nth-child(1){
  width: auto;
  max-width: 100%;
}

.workCat_select > div  {
  min-height: 10px !important;
}
